import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Cta from "../components/cta";

const LocationPage = () => (
  <Layout backgroundColor="salmon">
    <Seo title="Location" />

    <h1>Things to Do</h1>

    <p>
      There are many things to do and see over the weekend. We’ve listed a few
      of our favorites.
    </p>

    <h3>SFMOMA</h3>
    <p>
      One of Micah’s favorite artists, Nam June Paik, is being shown at SFMOMA.
      Additionally, Diego Rivera’s <em>Pan American Unity,</em> which Monica
      wrote about in her book, is on view in the free ground floor gallery.
      Don’t miss the photography exhibit,{" "}
      <em>Diana Markosian: Santa Barbara!</em>
    </p>

    <h3>Kabuki Springs and Spa</h3>
    <p>Monica loves to take the waters in this special setting. In Japantown</p>

    <h3>Lakehouse Jazz</h3>
    <p>
      Intimate <a href="https://www.eventbrite.com/e/lakehouse-jazz-tickets-74499918261">
        jazz experience
      </a> at Stow Lake boathouse on Friday night. Or take the kids out on a pedal
      boat or row boat during the day!
    </p>

    <h3>Fleet Week</h3>
    <p>
      San Francisco Fleet Week is starting Sunday, October 3. If you love Blue
      Angels and the navy, this is right up your alley.
    </p>

    <h3>Farmers Market</h3>
    <p>Outer Sunset Farmers Market and Mercantile every Sunday</p>

    <Cta url="/sf-culture" text="Check out some SF Culture" />
  </Layout>
);

export default LocationPage;
